.main {
    display: flex;
    flex-direction: column;
}


.row {
    display: flex;    
    gap:50px;
}

.labelFieldPair{
    display: flex;    
    gap:10px;
}

.label {

    min-width: 65px;
}