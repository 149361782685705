
.viewPortalButton {    
    color: gray;
    min-width: 150px;
    border : solid 3px rgb(52, 164, 224);
    border-radius: 15px;                
    background: orange;
    border-radius: 15px;                
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;        
    margin-left: 0px;
    margin-top: 15px;        
    padding: 10px;    
}


.viewPortalButton:hover {        
    background: rgb(249, 186, 70);    
}


.viewPortalText {        
        font-size:  large;        
        color: rgb(255, 255, 255);            
        font-weight: bold;
        padding-right: 20px;
}

.viewPortalText2 {
    color: rgb(255, 255, 255);            
}



.viewPortalHouse{
    color: white;     
    margin-right: 5px;   
}

.viewPortalLayout
{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}   

.viewPortalTextLayout {    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 10px;
}


