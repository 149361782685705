.main {
    display: flex;
    flex-direction: row;    
    user-select: none;
    margin : 5px;
    
}


.enquiry {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap :15px;
}

.row {

    display: flex;
    flex-direction: row;    
    justify-content: space-between;
}

.labelFieldPair {
    display:flex;
    flex-direction: column;
}


.label {
    color: gray;
    font-size: small;
}


.field {
    display: flex;
    border: solid 1px lightblue;
    border-radius: 5px;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
    background: rgb(249, 249, 249);
    color: rgb(189, 189, 189);
}
.fieldSmall {
    width:200px;
}

.fieldLarge {
    width:500px;
}


.fieldMessage {
    height: 70px;
    
}



.cat {
    display : flex;
    justify-content: center;                
}



.options {        
    flex-direction: row;    
    width:500px;
    display: flex;
    gap: 30px;
}


.option {
    display: flex;
    flex-direction: row;
    align-items:flex-end;    
    gap:10px;   
    cursor: pointer; 
}


.utilityoption {
    margin-top: 20px;
    width: 100%;    
    display: flex;
    gap:10px;
    justify-content: start;
    cursor: pointer;

}


.sendEmail {
    background:#00deb6;
    height : 50px;
    border: 2px solid gray;
    border-radius: 5px;
    width: 95%;
    margin-top: 50px;
}

.sendEmail:hover {
    background:#1ef5cd;
}

.title{
    font-size: x-large;
    font-weight: bold;
}

.add2{
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: end;
    
}





