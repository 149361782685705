.ctaButton {    
    color: rgb(0, 0, 0);
    min-width: 150px;
    border : solid 3px rgb(76 174 209);
    border-radius: 15px;                
    background:rgb(217 44 87);
    /* background:rgb(90 90 90); */
    border-radius: 15px;                
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;        
    margin-left: 10px;
    margin-top: 20px;
    width:425px;    
    /* padding: 10px;             */
    height: 80px;
    
}

.ctaButtonMobile { 
    width:95%;   
    margin: 20px 10px 20px 10px;
    
}


.ctaButton:hover {  
    text-decoration: underline;    
    color:rgba(255, 255, 255, 0.937) ;
}



.ctaHouse{
    color: white;     
    margin-right: 5px;   
}

.ctaLayout
{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}   

.ctaTextLayout {  
    height: 100%;      
    display: flex;
    flex-direction: column;    
    margin-left: 10px;
    justify-content: space-evenly;
}


.ctaText {        
    font-size:  large;        
    color:rgba(255, 255, 255, 0.937) ;
    font-weight: bold;    
}

.ctaText2 {
color: rgb(255, 255, 255);            
}
