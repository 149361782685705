h1{
    /* color: rgb(135, 160, 185); */
    color: rgb(94, 94, 94); 
    font-weight: 600;
    font-size: 26px;
    margin : 10px 0px 0px 0px 
  }


p{       

    font-size: 15px;
    margin : 5px 5px 0px 0px
}

h2{
    color:rgb(255, 118, 0, .94);
    margin : 35px 0px 0px 0px ;
    font-weight: 450;
    font-size: 21px;
 }

 h3{
  
  font-weight: 500;
  font-size: 16px;
  margin : 25px 0px 5px 0px ;
}

 b{    
  font-weight: 640;
  color: black;  
}

 table {
    background-color: #f7f7f7;
    border-collapse: collapse;
    margin : 15px 0px 0px 0px ;
    width: 100%;
  }
  
  th, td {
    font-size: 14px;
    padding: 3px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  tr:hover {
    background-color:rgb(223, 224, 225);
    /* text-decoration: underline; */
}

 a:link {
    font-size: 15px;          
    background-color: transparent;
    text-decoration: none;
    color:black;
    text-decoration: underline;
  }
  a:visited {    
    background-color: transparent;
    text-decoration: none;
    color:black;
  }
  a:hover {
    color:rgba(227, 139, 8, 0.937);
    background-color: transparent;
    text-decoration: underline;
   
  }
  a:active {
    color:rgba(227, 139, 8, 0.937);
    background-color: transparent;
    text-decoration: underline;
  }



  li{
    margin: 3px;
  }

.portalButton {
    background: rgb(230, 149, 0);
    min-width: 150px;
    border : solid 2px rgb(213, 213, 213);
    border-radius: 5px;            
    height: 25px;
}

.portalButton:hover {
  border : solid 2px rgb(187, 187, 187);
}

.portalLink {
   text-decoration: none !important;
   
  font-weight: 500;
}

.portalLink:hover {    
  text-decoration: underline !important;
  color: rgb(115, 146, 200) !important;
  font-weight: 500;   
}



.infoLink {
    background: rgb(130, 154, 198);
    min-width: 150px;
    border : solid 2px rgb(213, 213, 213);
    border-radius: 5px;    
    height: 25px;    
}


.viewInfoButton {
  background: rgb(115, 146, 200);
  min-width: 65px;
  border : solid 2px rgb(213, 213, 213);
  border-radius: 10px;    
  height: 30px;    
  color: white; 
}

.viewInfoButtonIcon {
  color:white;
}

.viewInfoButtonIcon:hover {
  color:white;
}

.viewInfoButtonLink
{
  text-decoration: none !important;
  color: white !important;
  font-size: 13px !important;


}
.viewInfoButtonLink:hover
{
  text-decoration: underline !important;    
  color: white !important;
}


.tableButtonAlignment {
  display:flex;
  align-items:center;
  justify-content:space-between;
  height: 100%;
}




.searchButton {
  background: orange;
   min-width: 100px; 
  border : solid 2px rgb(213, 213, 213);
  border-radius: 10px;    
  height: 30px;    
  color: rgb(0, 0, 0);   
  font-size:small;
  text-wrap: nowrap;
  margin-top: 10px;    
}

.mainViewButton {  
  height: 50px;    
  font-size: larger;
  padding: 10px 10px;
}


.searchButtonIcon {
  color:white;    
  margin-left: 5px;
}



.searchButton:hover {  

  text-decoration: underline !important;   
}



.searchButtonLink
{
  text-decoration: none !important;
  color: white !important;
  font-size: 13px !important;


}
.searchButtonLink:hover
{
  text-decoration: underline !important;    
  color: white !important;

}


.hideForMobile{

  display: none;
  

}










 
 