.portal {
    border : 0px solid black;
    margin: 5px;
    margin-bottom: 50px;
}

.filters {
    margin: 0px 0px 0px 5px;
    display: flex;
    flex-direction: row;    
    flex-wrap: wrap;   
    gap:10px;
    height:auto;
    
}

.filter {
    /* margin: 5px 0px 5px 15px; */
}

.priceFilter{
    margin: 0px 25px 5px 45px;
}

.sortFilter{
    width:150px;
}

.priceFilterSelection {
    width: 200px;    
    margin : 0px 0px 0px 0px;
    display : flex;
    justify-content: space-between;


}

.priceFilterValue{
    user-select: none;
    color: rgb(0, 0, 0);

}

.priceFilterUnit {
    user-select: none;    
}




.filterName {

    color: orange;
    font-size: 15px;
    font-weight: 500;
}

.priceFilterName {

    color: orange;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}


.clearButton {

    /* height: 40px; */

    

}



.sortName {

    color: rgb(128, 128, 128);
    font-size: 15px;
    font-weight: 500;
}


.filterTown { 
    
    
    
}

.filterArea {
    width: 200px;    

}

.filterBeds {
    width: 150px;    

}



.filterType {
    width: 100px;    
}


.select {    
    height: 10px;                
}


.seachTitle {    
    margin: 2px 5px;    
    font-size: large;
}


.resultCount {
    color : rgb(0, 0, 0);    
    margin: 2px 0px;
    font-weight: bold;
    font-size: large;

}
.resultCountUnits
{
    
}


.filterButton
{
    color:rgb(255, 255, 255);
    background: orange;
    border: solid 2px rgb(212, 212, 212);    
    font-weight: bold;    
    height : 37px;    
    border-radius: 5px;
    margin-top: 19px;
    margin-right: 5px;        
    width:70px;
}

.filterButton:hover
{
    border: solid 2px rgb(184, 184, 184);    

}






.titledSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 5px 0px 0px; 
    color : rgb(55, 55, 55);
    font-size: small;
    
}


.navButton
{
    color:rgb(255, 255, 255);
    background: orange;
    border: solid 2px rgb(212, 212, 212);    
    font-weight: bold;
    width : 140px;
    height : 40px;    
    border-radius: 5px;
    margin: 10px 10px;
    
}

.navButton:hover
{
    color:rgb(255, 255, 255);
    background: orange;
    border: solid 2px rgb(55, 146, 199);    
    font-weight: bold;
    width : 140px;
    height : 40px;    
}


.navButtonDisabled 
{
    color:rgb(220, 220, 220);
    background: rgb(255, 255, 255);        
}
.navButtonDisabled:hover
{
    color:rgb(220, 220, 220);
    background: rgb(255, 255, 255);     
    border: solid 2px rgb(212, 212, 212);    
    
}


.navButtons {
    display:flex;    
    justify-content: space-between;
    align-items: center;

}


.navSmallButton
{
    color:rgb(95, 95, 95);
    border: solid 2px rgb(212, 212, 212);
    background: white;
    
    font-weight: bold;
    width : 100px;;
    height : auto;
    margin: 10px;
}




.navSmallButtonDisabled{
    color:rgb(193, 193, 193);
    border: solid 2px rgb(212, 212, 212);
    background: white;
    font-weight: bold;

    width : 100px;;
    height : auto;
    margin: 10px;

}

.select {
    font-size: 14px;            
}

.filterBar {
    margin : 10px 0px;
    display:flex;    
    justify-content: space-between;
    flex-wrap: wrap;
}