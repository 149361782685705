.propertyDetail{
    margin: 5px;
    border: 0px solid black;
    
}

.thumbNail{
    display:flex;
    width:40%;
    
}

.thumbNailMobile{
    display:flex;
    width:80%;
}




.mainImage {            
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius:10px;
}


.thumbImages {
    border:'0px solid black';
    display: flex;
}


.thumbImage {
    border-radius:10px;
    margin: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pictureSection{
    margin-top: 5px;
    flex :10;
    display: flex;
    flex-direction:column}









