

.listings {       
    display: flex;    
    flex-wrap: wrap;
    background: rgb(255, 255, 255);
    border-radius: 10px;    
    margin:10px;
    background: rgb(241, 241, 241);
    flex-direction: row;
}

.listing {   
    /* width :600px;  */
    /* height: auto;     */
    border-radius: 10px;         
    margin: 8px;    
    display:flex;
    flex-direction: row;    
    border: 1px solid rgb(202, 202, 202);
    user-select: none;

}


.listing-lhs {          
    display:flex;
    flex-direction: column;        
    background: gainsboro;
    border-top-left-radius: 10px;    
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 10px;    
    
    /* flex: 4; */
}

.listing-rhs {
    /* flex:2;                */
    border-radius: 10px;    
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
    /* background: green; */
    padding: 5px;
    background: white;
    width:320px;
}



.cover {
    object-fit: cover;
    width: 50px;
    height: 100px;
  }



.image1 
{  
    object-fit: cover;
    width :350px;
    height: 277px;
    border-top-left-radius: 10px;        
}


.price-container {        
    margin: 5px;    
    flex:1;
}


.listing-pcm {
    font-weight: bold;
    font-size: 16px;
}

.listing-pw {
    font-weight: normal;
    font-size: 14px;
}

.belowImageInfoContainer {

    display:flex;
    justify-content:space-between;
    margin: 10px;

}




.listing-address-location {
    display:flex;    
    margin: 2px;
}



.listing-address {
    flex:1;
    font-weight: bold;    
    font-size: 14px;    
    margin: 2px;
    color: rgb(46, 46, 46);
    
}

.listing-booking-status {
    flex:1;
    font-weight: bold;
    font-size: large;
    margin: 2px;    
    
    
}




.listing-subheader {    
    margin: 2px;
    font-size: 14px;    
    user-select: none;
}

.listing-text {
    margin: 10px 0px 0px 0px;    
    font-size: 14px;
    color: rgb(84, 84, 84);
    user-select: none;
}



.listing-pic-subheading {
    font-weight: bold;    
    margin-top: 0px;
    font-size: 18px;

}

.listing-pic-subheading2 {
    font-size: smaller;
    margin-top: 5px;
    margin-bottom: 15px;
    
}



  .addedRecuded {
    display:flex;
    align-items:center;
    color:green;
    font-weight: 500;
    font-size: 12px;
    user-select: none;

  }

  

  .textAndBanner{
    display:flex;
    flex-direction: column;
    height: 100%;
    
  }

  .mainText{    
    flex:3;
  }

  .banner{    
    
    flex:1;
    margin-bottom: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
  }