.mainStats
{    
    border-radius: 15px;
    width:100%;
    align-items: center;    
    display: flex;
    flex-direction: column;    

}

.mainStatsTitle{
    
    user-select: none;
    margin: 5px 5px;
    


}



.statRow
{        
    justify-content: space-between;
    align-items: center;    
    display: flex;
    flex-direction: row;
    width:100%;
}



.statRowMini
{    
     width: 100%; 
    justify-content: space-between;
    
    align-items: center;    
    display: flex;
    flex-direction: row;
}










.title {    
    font-weight: normal;
    color: rgb(0, 0, 0);        
}


.stat {    
    flex:1;
    user-select: none;
    margin: 0px 5px;    
    cursor: pointer;        
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    color:rgb(0, 0, 0);    
    border-radius: 5px;
    border: solid 3px #c2edff        ;
    min-width: 70px;
    
    
}



.stat:hover {    
    border: solid 3px #70d2ff


}

.statTitle {
    
    color: orange;
    font-size: 15px;
    font-weight: 500;
}
.statValue {
    
    
    
    font-weight: 500;
}


a{
    color:aqua;
}



.viewInfoLink {
    text-decoration:none !important;        
    
}

.viewInfoLink:hover {
    text-decoration:none !important;      
    color: black;      
}










.viewInfoIcon {
    margin-top: 5px;
    margin-left: 3px;
    color:rgb(0, 0, 0);
    color:rgb(115, 146, 200);


}